<template>
  <div class="era-content-container">
    <section id="home" class="era-home-container">
      <div class='era-home-video'>
        <video muted class="hcs-video-show" loop="loop" autoplay="autoplay">
          <source src="@/assets/images/home-video.mp4" type="video/mp4">
        </video>
      </div>
      <div class="era-home-title-box flex-column justify-center">
        <div class="era-home-title">微观纪元，用量子的技巧筑造新的世界</div>
        <div class="era-home-des">求知、探索、创新、求解</div>
      </div>
    </section>
    <v-container fluid>
      <section id="aboutUs" class="era-aboutus-container" ref="firstContext">
        <div class="bg1" style="margin-top:30vw;"></div>
        <div class="era-card era-about-us flex-row">
          <div>
            <div class="era-about-us-border"></div>
          </div>
          <div class="flex-1">
            <div class="era-about-us-title">关于我们</div>
            <div class="era-about-us-content">
              合肥微观纪元数字科技有限公司是一家成立于2022年的量子计算领域创业公司，核心团队来自国内外顶尖高校，并有一支来自不同领域的国家级专家顾问团队。在微观纪元，我们学习、创新、钻研、合作，共同解决行业与量子计算结合最前沿的问题，使用前所未有的路径和工具从真正意义上改变世界，开始新的纪元。
            </div>
            <div class="era-about-us-more" @click="onAboutUs()">了解详情</div>
            <!-- <i class="iconfont icon-arrow"></i> -->
          </div>
        </div>
        <div class="over-tips" v-show="showContext">
          <div class="tips-item" v-for="(item, index) in tips" :key="item" :class="nowIndex == index ? 'active' : ''"
            @click="indexChange(index)">
            <span class="item-text">{{ item }}</span>
          </div>
        </div>
        <!-- <div class="bg2"></div> -->
      </section>
      <section id="technology" class="era-technology">
        <div class="bg2"></div>
        <v-card class="era-card era-quantum-computing">
          <v-row no-gutters>
            <v-col-1>
              <div class="era-technology-title-border" style="background-color: rgb(205, 93, 255);"></div>
            </v-col-1>
            <v-col style="margin-left: 3.5vw;">
              <v-row>
                <div class="era-product-right-box">
                  <div class="era-product-title-box flex-row align-center">
                    <div class="era-product-title">我们的设计工具：量子计算机</div>
                  </div>
                  <div class="era-product-content">
                    由于量子叠加性，多量子比特承载的信息量是指数式增长的。利用量子特性操控多量子比特可以对其承载的信息进行高效地处理，从而解决经典计算机难以解决的问题，比如生物分子体系中的电子结构求解。在量子算法开发者层面，我们延续了国际主流框架风格，并进一步地将模拟器进行底层性能优化，让开发者和使用者能在更复杂的问题、更简易的交互环境、更安全可控的框架中使用量子线路。
                  </div>
                </div>
              </v-row>
              <v-row no-gutters style="margin-top: 1.1vw;margin-bottom: 1.4vw;">
                <!-- <div class="era-quantum-img-container"> -->
                <v-col-3 style="background: rgb(239, 239, 239);width: 40%;height:18vw;">
                  <div class="era-quantum-img-box era-quantum-img-box1" style="width: 75%;margin:10px auto;">
                    <v-img class="img6" width="7.64rem" src="@/assets/images/superconductivity6.png"
                      lazy-src="@/assets/images/superconductivity6.png"></v-img>
                    <v-img class="img7" width="7.64rem" src="@/assets/images/superconductivity7.png"
                      lazy-src="@/assets/images/superconductivity7.png"></v-img>
                    <v-img class="img8" width="7.64rem" src="@/assets/images/superconductivity8.png"
                      lazy-src="@/assets/images/superconductivity8.png"></v-img>
                    <!-- <v-img class="img1" width="7.64rem" src="@/assets/images/superconductivity1.png"
                      @load="superconductivityLoad()"></v-img> -->
                    <v-img class="img1" width="7.64rem" src="@/assets/images/superconductivity1.png"
                      lazy-src="@/assets/images/superconductivity1.png"></v-img>
                    <v-img class="img9" width="7.64rem" src="@/assets/images/superconductivity9.png"
                      lazy-src="@/assets/images/superconductivity9.png"></v-img>
                    <v-img class="img10" width="7.64rem" src="@/assets/images/superconductivity10.png"
                      lazy-src="@/assets/images/superconductivity9.png"></v-img>
                    <!-- <div
                      class="icon1"
                      v-show="showQuantumLine"
                    >
                      <i
                        id="container1"
                        class="iconfont icon-superconductivityline1"
                      ></i>
                    </div>
                    <div
                      class="icon2"
                      v-show="showQuantumLine"
                    >
                      <i
                        id="container2"
                        class="iconfont icon-superconductivityline2"
                      ></i>
                    </div>
                    <div
                      class="icon3"
                      v-show="showQuantumLine"
                    >
                      <i
                        id="container3"item
                        class="iconfont icon-superconductivityline3"
                      ></i>
                    </div>
                    <div
                      class="icon4"
                      v-show="showQuantumLine"
                    >
                      <i
                        id="container4"
                        class="iconfont icon-superconductivityline4"
                      ></i>
                    </div>
                    <div
                      class="icon5"
                      v-show="showQuantumLine"
                    >
                      <i
                        id="container5"
                        class="iconfont icon-superconductivityline5"
                      ></i>
                    </div>
                    <div
                      class="icon6"
                      v-show="showQuantumLine"
                    >
                      <i
                        id="container6"
                        class="iconfont icon-superconductivityline6"
                      ></i>
                    </div> -->
                    <v-img class="img2" width="7.64rem" src="@/assets/images/superconductivity2.png"
                      lazy-src="@/assets/images/superconductivity2.png"></v-img>
                    <v-img class="img3" width="7.64rem" src="@/assets/images/superconductivity3.png"
                      lazy-src="@/assets/images/superconductivity3.png"></v-img>
                    <v-img class="img4" width="7.64rem" src="@/assets/images/superconductivity4.png"
                      lazy-src="@/assets/images/superconductivity4.png"></v-img>
                    <v-img class="img5" width="7.64rem" src="@/assets/images/superconductivity5.png"
                      lazy-src="@/assets/images/superconductivity5.png"></v-img>
                    <v-img class="img11" width="7.64rem" src="@/assets/images/superconductivity11.png"
                      lazy-src="@/assets/images/superconductivity11.png"></v-img>
                    <v-img class="img12" width="7.64rem" src="@/assets/images/superconductivity12.png"
                      lazy-src="@/assets/images/superconductivity12.png"></v-img>
                    <v-img class="img13" width="7.64rem" src="@/assets/images/superconductivity13.png"
                      lazy-src="@/assets/images/superconductivity13.png"></v-img>
                  </div>
                  <div class="era-superconductivity era-text">超导量子计算机</div>
                </v-col-3>
                <v-col-3
                  style="background: rgb(239, 239, 239);margin-left: 10px; width: 40%;height:18vw;position:relative">
                  <!-- <div class="era-quantum-img-box era-quantum-img-box2" style="border: 2px solid pink"> -->
                  <v-img class="img14" width="6.13rem" src="@/assets/images/annealing1.png"
                    style="width: 70%;margin:10px auto 5px;" lazy-src="@/assets/images/annealing1.png"></v-img>
                  <v-img class="img15" width="0.22rem" src="@/assets/images/annealing2.png"
                    lazy-src="@/assets/images/annealing2.png"></v-img>
                  <v-img class="img16" width="0.2rem" src="@/assets/images/annealing3.png"
                    lazy-src="@/assets/images/annealing3.png"></v-img>
                  <v-img class="img17" width="0.17rem" src="@/assets/images/annealing4.png"
                    lazy-src="@/assets/images/annealing4.png"></v-img>
                  <!-- <v-img
                      class="img15"
                      width="0.22rem"
                      src="@/assets/images/annealing2.png"
                    ></v-img>
                    <v-img
                      class="img16"
                      width="0.2rem"
                      src="@/assets/images/annealing3.png"
                    ></v-img>
                    <v-img
                      class="img17"
                      width="0.17rem"
                      src="@/assets/images/annealing4.png"
                    ></v-img> -->
                  <!-- </div> -->
                  <div class="era-annealing era-text1">退火量子计算机</div>
                </v-col-3>
                <v-col>
                  <div class="era-quantum-button">了解详情</div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
        <!-- <div class="era-card era-beneficiary-group">
          <div class="era-product-right-box">
            <div class="era-product-title-box flex-row align-center">
              <div class="era-product-title-border"></div>
              <div class="era-product-title">谁会从我们的工作中受益</div>
            </div>
          </div>
          <div class="era-beneficiary-box">
            <div class="era-beneficiary-item">
              <img
                class="img1"
                width="315px"
                style="width: 16.5vw;"
                src="@/assets/images/beneficiary1.png"
                @load="imgLoad()"
              />
              <v-img
                class="img4"
                width="16.5vw"
                src="@/assets/images/beneficiary4.png"
              ></v-img>
              <div class="era-beneficiary-name">创新药研发者</div>
            </div>
            <div class="era-beneficiary-item">
              <img
                class="img2"
                width="315px"
                style="width: 16.5vw;"
                src="@/assets/images/beneficiary2.png"
                @load="imgLoad()"
              />
              <v-img
                class="img5"
                width="16.5vw"
                src="@/assets/images/beneficiary5.png"
              ></v-img>
              <div class="era-beneficiary-name">新材料设计工程师</div>
            </div>
            <div class="era-beneficiary-item">
              <img
                class="img3"
                width="315px"
                style="width: 16.5vw;"
                src="@/assets/images/beneficiary3.png"
                @load="imgLoad()"
              />
              <v-img
                class="img6"
                width="16.5vw"
                src="@/assets/images/beneficiary6.png"
              ></v-img>
              <div class="era-beneficiary-name">合成生物学工程师</div>
            </div>
          </div>
        </div>
        <div class="era-line4 flex-row align-center">
          <div class="era-identity1">M</div>
          <div class="era-identity2">I</div>
          <div class="era-identity3">Q</div>
          <div class="era-identity4">R</div>
          <div class="era-identity5">O</div>
        </div>
        <div class="era-card era-cooperative-partner flex-row">
          <div>
            <div class="era-cooperative-partner-border"></div>
          </div>
          <div class="flex-1">
            <div class="era-cooperative-partner-title">合作伙伴</div>
            <div class="era-partner-img-box">
              <div
                class="era-partner-img"
                v-for="(item, index) in 6"
                :key="index"
              >
                <v-img
                  class="img1"
                  :src="require('@/assets/images/partner' + item + '.png')"
                  @load="imgLoad()"
                ></v-img>
                <v-img
                  class="img2"
                  :src="require('@/assets/images/partner' + item + '-1.png')"
                ></v-img>
              </div>
            </div>
            <i class="iconfont icon-arrow"></i>
          </div>
        </div> -->
        <div class="bg2"></div>
      </section>
      <section id="product" class="era-product">
        <!-- <div class="era-line1"></div> -->
        <v-card elevation="12" id="catalytic" class="era-card vCard flex-row">
          <v-row no-gutters>
            <v-col-1>
              <div class="era-plat-title-border"></div>
            </v-col-1>
            <v-col style="margin-left: 3.5vw;">
              <div class="era-product-title-box flex-row align-center">
                <div class="era-product-title">微观纪元<span style="color:rgb(16, 171, 253);">开算平台</span></div>
              </div>
              <div class="era-product-content" style="margin-top: 4.5vw;">
                微观纪元开发的基于云原生技术的量子计算应用软件着力于将量子算法应用于生命科学、量子计算，新材料、能源等领域中，本软件面向开发者和用户，通过发挥量子算法与经典算法的各自优势，使用量子与经典算力资源，更高效能地解决实际问题。
              </div>
              <div class="era-plat-button" @click="onCatalytic()">了解详情</div>
            </v-col>
            <v-col>
              <img class="era-catalytic-img" width="100%" height="100%" src="@/assets/images/plat.png" loading="lazy" />
            </v-col>
          </v-row>
        </v-card>
        <div class="bg2"></div>
        <v-card elevation="12" id="preparations" class="era-card vCard">
          <v-row no-gutters>
            <v-col-1>
              <div class="era-plat-title-border" style="background-color:rgb(254, 25, 121)"></div>
            </v-col-1>
            <v-col style="margin-left: 3.5vw;">
              <div class="era-product-title-box flex-row align-center">
                <div class="era-product-title">微观纪元<span style="color:rgb(254, 25, 121)">药物筛选</span></div>
              </div>
              <div class="era-product-content" style="margin-top: 1.5vw;">
                药物虚拟筛选是一种用于药物发现的计算技术，通过搜索真实或虚拟的小分子库来确定潜在的候选靶点，可以潜在地提高先导化合物的发现过程，是众多制药公司正在探索的简化药物发现过程的新兴策略之一。

                <br><br>虚拟筛选的两种策略，分别是基于配体的筛选技术和基于结构的筛选技术，前者是采用活性配体结构的拓扑相似性搜索，后者则是采用目标受体的三维特征约束。通过化合物库构建、同源建模、分子生成、虚拟筛选、ADMET性质预测和精确结合自由能计算等解决方案，让药物筛选效率提高，成功率提高。
              </div>
              <div class="era-plat-button" @click="onDrug()" style="background-color:rgb(254, 25, 121)">了解详情</div>
            </v-col>
            <v-col>
              <img class="era-catalytic-img" width="100%" height="100%" src="@/assets/images/medicine.png"
                loading="lazy" />
            </v-col>
          </v-row>
        </v-card>
      </section>
      <section id="cooperate" class="era-technology">
        <div class="bg2"></div>
        <v-card class="era-card era-quantum-computing">
          <v-row no-gutters>
            <v-col-1>
              <div class="era-technology-title-border" style="background-color: rgb(255, 192, 103);"></div>
            </v-col-1>
            <v-col style="margin-left: 3.5vw;">
              <v-row>
                <div class="era-product-right-box">
                  <div class="era-product-title-box flex-row align-center">
                    <div class="era-product-title">合作公司</div>
                  </div>
                  <div class="era-product-content">
                    通过与多家量子计算机厂商合作、以及与国内外多家超算和智算中心联动，我们确保了充沛的算力资源供给。此外，我们也和国内外多个高校、研究院所的课题组合作，汲取相关领域最前沿的技术养分。目前，平台已经服务了上海、深圳、苏州的多家生物医药企业，可以帮助客户将药物发现阶段的成本降低，研发时间压缩。
                  </div>
                </div>
              </v-row>
              <v-row no-gutters style="margin-top: 1.1vw;margin-bottom: 7vw;">
                <img class="era-catalytic-img" width="95%" height="100%" src="@/assets/images/cooperate.png"
                  loading="lazy" />
              </v-row>
            </v-col>
          </v-row>
        </v-card>
        <div class="bg2"></div>
      </section>
      <section id="new" class="era-technology">
        <!-- <div class="era-line2"></div> -->
        <v-card class="era-card era-quantum-computing">
          <v-row no-gutters>
            <v-col-1>
              <div class="era-technology-title-border" style="background-color: rgb(207, 0, 0);"></div>
            </v-col-1>
            <v-col style="margin-left: 3.5vw;">
              <v-row>
                <div class="era-product-right-box">
                  <div class="era-product-title-box flex-row align-center">
                    <div class="era-product-title">新闻</div>
                  </div>
                  <div class="era-product-content new-context">
                    <div class="new-top">
                      <div class="new1 item" @click="newClick(newsList[0].newsId)">
                        <div class="img">

                          <v-img v-if="newsList[0].newsPic" height="8vw" :lazy-src="imgUrl + newsList[0].newsPic"
                            :src="imgUrl + newsList[0].newsPic" />
                        </div>
                        <div class="text">
                          <div class="texts">{{ newsList[0].newsTitle }}</div>
                          <div class="time">{{newsList[0].createTime}}</div>
                        </div>
                      </div>
                      <div class="new2 item" @click="newClick(newsList[1].newsId)">
                        <div class="img">

                          <v-img v-if="newsList[1].newsPic" height="8vw" :lazy-src="imgUrl + newsList[1].newsPic"
                            :src="imgUrl + newsList[1].newsPic" />
                        </div>
                        <div class="text">
                          <div class="texts">{{ newsList[1].newsTitle }}</div>
                          <div class="time">{{newsList[1].createTime}}</div>
                        </div>
                      </div>
                    </div>
                    <div class="new-btn">
                      <div class="new3 item" @click="newClick(newsList[2].newsId)">
                        <div class="img">

                          <v-img v-if="newsList[2].newsPic" height="8vw" :lazy-src="imgUrl + newsList[2].newsPic"
                            :src="imgUrl + newsList[2].newsPic" />
                        </div>
                        <div class="text">
                          <div class="texts">{{ newsList[2].newsTitle }}</div>
                          <div class="time">{{newsList[2].createTime}}</div>
                        </div>
                      </div>
                      <div class="new4 item" @click="newClick(newsList[3].newsId)">
                        <div class="img">

                          <v-img v-if="newsList[3].newsPic" height="8vw" :lazy-src="imgUrl + newsList[3].newsPic"
                            :src="imgUrl + newsList[3].newsPic" />
                        </div>
                        <div class="text">
                          <div class="texts">{{ newsList[3].newsTitle }}</div>
                          <div class="time">{{newsList[3].createTime}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </v-row>
              <v-row no-gutters style="margin-top: 1.1vw;margin-bottom: 7vw;">
                <!-- <img
                  class="era-catalytic-img"
                  width="95%"
                  height="100%"
                  src="@/assets/images/cooperate.png"
                /> -->
              </v-row>
            </v-col>
          </v-row>
        </v-card>
        <div class="bg2"></div>
      </section>
      <section id="joinUs" class="era-technology">
        <div class="era-card era-about-us flex-row">
          <div>
            <div class="era-about-us-border" style="background-color: rgb(147, 121, 94);"></div>
          </div>
          <div class="flex-1">
            <div class="era-about-us-title">加入我们</div>
            <div class="
              era-about-us-content">
              未来，微观纪元将立足量子计算，生物、材料、能源等领域，致力于在产业中实现实际量子优越性。<br>
              让我们一起探索微观量子世界。
            </div>
            <div class="era-about-us-more" style="background-color: rgb(147, 121, 94);" @click="onJoinUs()">了解详情</div>
          </div>
        </div>
        <div class="bg3"></div>
      </section>
      <section class="era-imgs-container">
        <div class="era-footer-img-box">
          <div class="era-footer-img">
            <v-img class="img1" src="@/assets/images/Quantum-computing1.png"
              lazy-src="@/assets/images/Quantum-computing1.png">
            </v-img>
            <v-img class="img2" src="@/assets/images/Quantum-computing2.png"
              lazy-src="@/assets/images/Quantum-computing2.png">
            </v-img>
          </div>
          <div class="era-footer-img">
            <v-img class="img1" src="@/assets/images/Computational-biology1.png"
              lazy-src="@/assets/images/Computational-biology1.png">
            </v-img>
            <v-img class="img2" src="@/assets/images/Computational-biology2.png"
              lazy-src="@/assets/images/Computational-biology2.png">
            </v-img>
          </div>
          <div class="era-footer-img">
            <v-img class="img1" src="@/assets/images/New-energy1.png" lazy-src="@/assets/images/New-energy1.png">
            </v-img>
            <v-img class="img2" src="@/assets/images/New-energy2.png" lazy-src="@/assets/images/New-energy2.png">
            </v-img>
          </div>
          <div class="era-footer-img">
            <v-img class="img1" src="@/assets/images/Advanced-material1.png"
              lazy-src="@/assets/images/Advanced-material1.png">
            </v-img>
            <v-img class="img2" src="@/assets/images/Advanced-material2.png"
              lazy-src="@/assets/images/Advanced-material2.png">
            </v-img>
          </div>
          <div class="era-footer-img">
            <v-img class="img3" src="@/assets/images/explore.png" lazy-src="@/assets/images/explore.png">
            </v-img>
          </div>
        </div>
      </section>
    </v-container>

  </div>
</template>

<script>
import { getHome } from "@/api/index";
import { Graph, Vector } from "@antv/x6";
import { getNews } from "@/api/index";
export default {
  name: "Home",
  data() {
    return {
      showContext: false,
      vwHeight: null,
      nowIndex: null,//当前所在索引
      actionOver: true,//跳转动画完结
      timer: null,
      tips: ['关于我们', '设计工具', '开算平台', '药物筛选', '合作公司', '新闻资讯', '加入我们'],
      queryParams: {
        pageNum: 1,
        pageSize: 4,
      },
      newsList: [],
      loading: false,
      imgUrl: process.env.VUE_APP_BASE_API,
    };
  },
  created() {
    this.getnewsList()
  },
  mounted() {
    // 计算vw高度
    this.vwHeight = window.innerWidth / 100;
    window.addEventListener("scroll", this.scrollTo);
    window.addEventListener('resize', () => {
      this.handleResize();
    });
    if (this.$route.params.hash) {
      this.anchorScrollIntoTarget()
    }
    this.getHomeData()
  },
  methods: {
    newClick(id) {
      this.$router.push({
        path: "/news/" + id,
      });
    },
    async getnewsList() {
      this.loading = true;
      let res = await getNews(this.queryParams);
      if (res.code == 200) {
        this.newsList = res.rows;
        console.log(this.newsList[0].newsPic, res);
        // setTimeout(() => {
        //   this.loading = false;
        // }, 300);
      }
    },
    indexChange(item) {
      // 防止动画过程中tips的active不断变化导致tips闪烁
      clearTimeout(this.timer);
      if (parseInt(item) !== this.nowIndex) {
        this.actionOver = false
        this.timer = setTimeout(() => {
          this.actionOver = true
        }, 1000)
      }
      switch (item) {
        case 0:
          this.$vuetify.goTo('#aboutUs', { offset: -250 });
          this.nowIndex = 0
          break;
        case 1:
          this.$vuetify.goTo('#technology', { offset: -100 });
          this.nowIndex = 1
          break;
        case 2:
          this.$vuetify.goTo('#catalytic', { offset: 100 });
          this.nowIndex = 2
          break;
        case 3:
          this.$vuetify.goTo('#preparations', { offset: 100 });
          this.nowIndex = 3
          break;
        case 4:
          this.$vuetify.goTo('#cooperate', { offset: -100 });
          this.nowIndex = 4
          break;
        case 5:
          this.$vuetify.goTo('#new', { offset: 100 });
          this.nowIndex = 5
          break;
        case 6:
          this.$vuetify.goTo('#joinUs', { offset: 150 });
          this.nowIndex = 6
          break;
        default:
      }
    },
    scrollTo() {
      let offsetY = window.pageYOffset / this.vwHeight
      if (offsetY > 55) {
        this.showContext = true
      }
      if (offsetY > 370) {
        this.showContext = false
      }
      if (offsetY <= 55) {
        this.showContext = false
      }
      if (offsetY > 55 && offsetY < 102 && this.actionOver) {
        this.nowIndex = 0
      }
      if (offsetY > 102 && offsetY < 149 && this.actionOver) {
        this.nowIndex = 1
      }
      if (offsetY > 149 && offsetY < 202 && this.actionOver) {
        this.nowIndex = 2
      }
      if (offsetY > 202 && offsetY < 258 && this.actionOver) {
        this.nowIndex = 3
      }
      if (offsetY > 258 && offsetY < 307 && this.actionOver) {
        this.nowIndex = 4
      }
      if (offsetY > 307 && offsetY < 341 && this.actionOver) {
        this.nowIndex = 5
      }
      if (offsetY > 341 && offsetY < 370 && this.actionOver) {
        this.nowIndex = 6
      }
    },
    handleResize() {
      this.vwHeight = window.innerWidth / 100;
    },
    anchorScrollIntoTarget() {
      this.$vuetify.goTo(this.$route.params.hash, { offset: 156 });
      // document.querySelector(`#joinUs`).scrollIntoView({
      //   behavior: 'smooth',
      //   block: 'center'
      // })
    },

    /**
     * 图片加载完成
     */
    imgLoad() {
      this.imgFinishNum++;
      if (this.imgFinishNum == 10) {
        if (this.$route.params.hash) {
          this.$vuetify.goTo(this.$route.params.hash, { offset: 156 });
        }
      }
    },
    /**
     * 量子计算创建沿路径运动
     */
    createMovePath(num, width, height, path) {
      const graph = new Graph({
        container: document.getElementById("container" + num),
      });

      const node = graph.addNode({
        shape: "path",
        x: 6,
        y: 5,
        width: width,
        height: height,
        path: path,
        attrs: {
          body: {
            fill: "transparent",
            stroke: "transparent",
          },
        },
      });
      const view = graph.findViewByCell(node);
      if (view) {
        let rem = Number(window.localStorage.getItem("rem-font"));
        const findPath = view.findOne("path");
        if (findPath) {
          const token = Vector.create("circle", {
            r: (3 * rem) / 100,
            fill: "#72d858",
          });
          token.animateAlongPath(
            {
              dur: "3s",
              repeatCount: "indefinite",
            },
            findPath
          );
          token.appendTo(findPath.parentNode);
        }
      }
    },
    /**
     * 点击开放岗位
     */
    onPosition() {
      this.$router.push("/position");
    },
    /**
     * 点击开算平台
     */
    onCatalytic() {
      this.$router.push("/catalytic");
    },
    /**
     * 点击药物筛选
     */
    onDrug() {
      this.$router.push("/drug");
    },
    /**
     * 点击关于我们
     */
    onAboutUs() {
      this.$router.push("/aboutus");
    },
    onJoinUs() {
      this.$router.push("/joinus");
    },
    async getHomeData() {
      let res = await getHome();
      if (res.code == 200) {
        this.newsList = res;
      }
    },
    /**
     * 加载量子计算机图像
     */
    superconductivityLoad() {
      let rem = Number(window.localStorage.getItem("rem-font"));
      this.showQuantumLine = true;
      this.createMovePath(
        1,
        (182 * rem) / 100,
        (77 * rem) / 100,
        "M175,0 L175,77 L46,77 L66,51 L16,51 L32,30 L0,30"
      );
      this.createMovePath(
        2,
        (327 * rem) / 100,
        (90 * rem) / 100,
        "M327,0 L327,90 L3,90 L3,50"
      );
      this.createMovePath(
        3,
        (238 * rem) / 100,
        (105 * rem) / 100,
        "M238,0 L238,105 L0,105"
      );
      this.createMovePath(
        4,
        (283 * rem) / 100,
        (60 * rem) / 100,
        "M0,0 L0,60 L140,60 L130,49 L283,49"
      );
      this.createMovePath(
        5,
        (235 * rem) / 100,
        (96 * rem) / 100,
        "M0,0 L0,96 L235,96 L202,53"
      );
      this.createMovePath(
        6,
        (315 * rem) / 100,
        (122 * rem) / 100,
        "M0,0 L0,100 L170,100 L190,122 L315,122 L292,100"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./Home.scss";

.hcs-video-show {
  border-bottom: 2px solid #ccc;
}

.era-aboutus-container {
  position: relative;
  z-index: 10;

  .era-card {
    z-index: 10;
  }

  .over-tips {
    width: 0.7vw;
    height: 35vw;
    position: fixed;
    // background: red;
    left: 2vw;
    top: 8vw;
    z-index: 100;

    .tips-item {
      width: 0.7vw;
      height: 0.7vw;
      margin-top: 3.8vw;
      border-radius: 50%;
      border: 1px solid #C3E9F5;
      background: #F7F7F7;
      position: relative;
      z-index: 100;

      .item-text {
        font-size: 0.7vw;
        position: absolute;
        left: 1vw;
        width: 5vw;
        color: #C3E9F5;
      }
    }

    .active {
      border-color: #388085;

      .item-text {
        color: #388085;
      }
    }

    .tips-item:hover {
      cursor: pointer;
      // border-color: #388085;

      .item-text {
        // color: #388085;
      }
    }
  }

  .over-tips::after {
    content: '';
    height: 35vw;
    width: 0px;
    border: 1px solid #C6DCE4;
    left: 0.34vw;
    top: 0px;
    display: block;
    position: absolute;
    z-index: 1;
  }
}

.era-text {
  position: absolute;
  left: 20%;
  bottom: 2vw;
}

.era-text1 {
  position: absolute;
  right: 32%;
  bottom: 2vw;
}

.img15 {
  animation: annealing1 1s linear 0s infinite alternate;
  -moz-animation: annealing1 1s linear 0s infinite alternate;
  -webkit-animation: annealing1 1s linear 0s infinite alternate;
  -o-animation: annealing1 1s linear 0s infinite alternate;
  position: absolute;
  left: 2.59rem;
  top: 1rem;
}

.img16 {
  animation: annealing2 1s linear 0.5s infinite alternate;
  -moz-animation: annealing2 1s linear 0.5s infinite alternate;
  -webkit-animation: annealing2 1s linear 0.5s infinite alternate;
  -o-animation: annealing2 1s linear 0.5s infinite alternate;
  position: absolute;
  top: 1.56rem;
  left: 1.56rem;
  opacity: 0.6;
}

.img17 {
  animation: annealing3 1s linear 1s infinite alternate;
  -moz-animation: annealing3 1s linear 1s infinite alternate;
  -webkit-animation: annealing3 1s linear 1s infinite alternate;
  -o-animation: annealing3 1s linear 1s infinite alternate;
  position: absolute;
  left: 2.03rem;
  opacity: 0.6;
}

.new-context {
  display: flex;
  flex-direction: column;

  .item:hover {
    cursor: pointer;
  }

  .item {
    flex: 1;
    background: #F7F7F7;
    padding: 0.2rem;
    display: flex;

    .img {
      width: 8vw;

      .v-responsive__content {
        width: none;
      }
    }

    .text {
      flex: 1;
      position: relative;

      .texts {
        margin-left: 0.2rem;
        margin-top: 0.18rem;
        font-weight: 500;
      }
      .time{
        position: absolute;
        bottom: 0rem;
        right: 0.5rem;
        font-size: 0.15rem;
        color: #B6B6B6;
      }
    }
  }

  .new-top {
    display: flex;
  }

  .new-btn {
    margin-top: 2vh;
    display: flex;
  }

  .new2 {
    margin-left: 1vw;
  }

  .new4 {
    margin-left: 1vw;
  }
}

::v-deep {
  .item {
    flex: 1;
    background: #F7F7F7;
    padding: 10px;
    display: flex;

    .img {
      width: 20%;
      border-radius: 10px;

      .v-responsive__content {
        width: 100% !important;
      }
    }
  }
}
</style>
